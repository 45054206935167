import {Route, Routes} from "react-router-dom";
import Index from "./pages/Index";
import Loader from "./pages/loader/Loader";

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Loader />} />
                <Route path="/loader" element={<Loader />} />
            </Routes>
        </>
    );
}

export default App;
